<template>
	<div id="flower-candle">
		<div class="fcbtn">
		<button class="flower" @click="saveFlowersCandle(2)"><img src="../../../assets/img/flower-ico.png"><span style="color: white;">献花</span>{{totalflower}}</button>
		<button class="candle" @click="saveFlowersCandle(1)"><img src="../../../assets/img/candle-ico.png"><span style="color: #d02d32;">点烛</span>{{totalcandle}}</button>
		</div>
		<vue-seamless-scroll class="scroll" :class-option="classOption" :data="totallist">
			<div class="scrollitem" v-for="(item,index) of totallist" :key="index+item" >
			<span >{{(item.type===0)?'献花':'点烛'}}</span><span >{{item.time|fordatatime}}</span>
			</div>
		</vue-seamless-scroll>
	</div>
</template>

<script>
	import apiUrls from "@api";
	import vueSeamlessScroll from "vue-seamless-scroll";
	
	export default {
	  name:'FlowerCandle',
	  components:{
		  vueSeamlessScroll
	  },
	  data() {
	    return {
		  totalflower:0,
		  totalcandle:0,
		  flowerlist:[],
		  candlelist:[],
		  totallist:[]
	    };
	  },
	  mounted() {
		this.getflocand(this.$route.query.id);
	  },	
	  filters: {
	    fordatatime: (value)=> {
	       value = value.slice(0, -3).split("-").join(".")
	       return value
	    }
	},
	computed: {
	            classOption() {
	            return {
	                step: 0.6, // 数值越大速度滚动越快
	                limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
	                hoverStop: false, // 是否开启鼠标悬停stop
	                direction: 1, // 0向下 1向上 2向左 3向右
	                openWatch: true, // 开启数据实时监控刷新dom
	                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
	                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
	                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
	            };
	            },
	        },
			
	  methods: {
		getflocand(id){
		  apiUrls.getFlowerCandle({ id }).then((res) => {
			this.flowerlist = res.results.flowerList.slice(0,50);
			this.candlelist = res.results.candleList.slice(0,50);
			this.totalflower = res.results.flowerNum;
			this.totalcandle = res.results.candleNum;
			
			this.totallist=[];
			this.flowerlist.map((item)=>{
				this.totallist.push(Object.assign({},item,{"type": 0})) 
			});
			this.candlelist.map((item)=>{
				this.totallist.push(Object.assign({},item,{"type": 1})) 
			});
			});
			
		},
		saveFlowersCandle(type){
		  let _id=this.$route.query.id
		  apiUrls.saveFlowerCandle({id:_id,type}).then((res)=>{
			  console.log(res);
			  this.getflocand(this.$route.query.id);
		  });
		  
		},
	}
	};
</script>

<style>
	#flower-candle{
		margin: 0 40px;
		text-align: center;
		border-top: 1px #f1f1f1 dashed;
	}
	.fcbtn{
		display: flex;
		justify-content: center;
	}
	.fcbtn img{
		vertical-align:sub;
		margin-right: 20px;
	}
	.fcbtn span{
		margin-right: 30px;
	}
	.flower,.candle{
		text-align: center;
		width: 142px;
		height: 62px;
		line-height: 60px;
		border-radius: 2px;
		border: 1px #d02d32 solid;
		margin: 26px 15px;
		background-color: white;
		color: #d02d32;
		outline: none;
	}
	.flower{
		background-color: #d02d32;
		color: white;
	}
	.scroll{
		overflow: hidden;
		height: 200px;
	}
	
	.scrollitem{
		height: 40px;
		display: flex;
		justify-content : space-between;
	}
	.scroll .scrollitem span{
		color: #999999;
	}
</style>
